import React, { Fragment } from "react";
import {
    BulkDeleteButton,
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    List,
    ListButton,
    maxLength,
    minLength,
    RefreshButton,
    required,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
} from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
});

const validateLanguageName = [required(), minLength(3), maxLength(64)];
const validateLocale = [required(), minLength(3), maxLength(32)];

const PostEditActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);

const CustomToolbar = (props) => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

const CodeBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

export const CustomDeleteButton = (props) => (
    <DeleteButton {...props} mutationMode="pessimistic" />
);

export const ProjectsList = (props) => {
    return (
        <List {...props} bulkActionButtons={<CodeBulkActionButtons />}>
            <Datagrid rowClick="edit">
                <TextField label="ID" source="id" />
                <TextField label="Name" source="name" />
                <DateField label="Date Created" source="createdAt" showTime />
                <DateField label="Date Updated" source="updatedAt" showTime />
                <DateField label="Last Activity" source="lastActivity" showTime />
                <EditButton />
                <CustomDeleteButton />
            </Datagrid>
        </List>
    );
};
import {stringify} from 'query-string';
import {fetchUtils, DataProvider} from 'ra-core';

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const DProvider : DataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    var url;
    if (resource === 'placements') {
      // work for only placements for now
      const {field, order} = params.sort;
      const filter = params.filter.name === undefined ? '' : params.filter.name;
      const search =
        params.filter.search === undefined ? '' : params.filter.search;
      url = `${apiUrl}/api/${resource}?field=${field}&order=${order}&filter=${filter}&search=${search}`;
    } else {
      url = `${apiUrl}/api/${resource}`;
    }
    return httpClient(url).then(({ headers, json }) => {
      const datax = json.data.map( item =>{
        return item.data
      });
      return {
        data: datax,
        total: json.pagination.limit
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/api/${resource}/${params.id}`).then(({json}) => ({
      data: json.data[0],
    })),

  getMany: (resource, params) => {
    const query = {
      ids: params.ids.toString(),
    };
    const url = `${apiUrl}/api/${resource}?${stringify(query)}`;
    return httpClient(url).then(({json}) => ({data: json.data}));
  },

  getManyReference: (resource, params) => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/api/${resource}?${stringify(query)}`;

    return httpClient(url).then(({headers, json}) => {
      if (!headers.has('content-range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?',
        );
      }
      return {
        data: json.data,
        total: parseInt(
          headers
            .get('content-range')
            .split('/')
            .pop(),
          10,
        ),
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/api/${resource}/${params.id}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json.data[0]})),

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/api/${resource}/${id}`, {
          method: 'POST',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then(responses => ({data: responses.map(({json}) => json.id)})),

  create: (resource, params) =>
    httpClient(`${apiUrl}/api/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({
      data: {...params.data, id: json.id},
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/api/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({json}) => ({data: json.data[0]})),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/api/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then(responses => ({data: responses.map(({json}) => json.id)})),
});

export default DProvider;
